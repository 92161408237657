export function fade() {
  let elementsArray = document.querySelectorAll(".u-fadein");
  window.addEventListener('scroll', fadeIn);

  function fadeIn() {
    for (var i = 0; i < elementsArray.length; i++) {
      var elem = elementsArray[i]
      var distInView = elem.getBoundingClientRect().top - window.innerHeight + 70;
      if (distInView < 0) {
        elem.classList.add("is-active");
      }
    }
  }
  fadeIn();
}
