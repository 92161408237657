import 'swiper/swiper-bundle.min.css';
import Swiper from 'swiper/bundle';

export function slider() {
  // top FV
  var mySwiperTop = new Swiper('.swiper-container-home', {
    // Optional parameters
    direction: 'horizontal',
    loop: true,
    slidesPerView: 1,
    speed: 1500,
    centeredSlides: true,
    autoplay: {
      delay: 4000,
      disableOnInteraction: true,
    },
    effect: 'fade',
    runCallbacksOnInit: true,
    breakpoints: {
      740: {
        slidesPerView: 1,
        spaceBetween: 20,
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
        },
      }
    },
  });
  var mySwiperColumn = new Swiper('.p-home-column__slide', {
    // Optional parameters
    direction: 'horizontal',
    loop: true,
    slidesPerView: 1,
    speed: 1500,
    autoplay: {
      delay: 4000,
      disableOnInteraction: true,
    },
    scrollbar: {
      el: '.swiper-scrollbar',
      hide: false,
      draggable: true,
    },
    runCallbacksOnInit: true,
    breakpoints: {
      740: {
        slidesPerView: 2,
        spaceBetween: 20,
      }
    },
  });
    var mySwiperColumn = new Swiper('.p-lp-column__slide', {
        // Optional parameters
        direction: 'horizontal',
        loop: true,
        slidesPerView: 1,
        speed: 1500,
        autoplay: {
            delay: 4000,
            disableOnInteraction: true,
        },
        scrollbar: {
            el: '.swiper-scrollbar',
            hide: false,
            draggable: true,
        },
        runCallbacksOnInit: true,
        breakpoints: {
            740: {
                slidesPerView: 2,
                spaceBetween: 20,
            }
        },
    });
  var mySwiperGallery = new Swiper('.p-home-gallery__slide', {
    autoplay: {
      delay: 0,
    },
    loop: true,
    speed: 4000,
    slidesPerView: 1,
    centeredSlides: true,
    preventInteractionOnTransition: true,
    breakpoints: {
      740: {
        slidesPerView: 3.5,
        spaceBetween: 20,
      }
    },
  });
  var mySwiperGallery = new Swiper('.p-about-gallery__slide', {
    autoplay: {
      delay: 0,
    },
    loop: true,
    speed: 4000,
    slidesPerView: 1,
    centeredSlides: true,
    preventInteractionOnTransition: true,
    breakpoints: {
      740: {
        slidesPerView: 3.5,
        spaceBetween: 20,
      }
    },
  });
  var mySwiperGroup = new Swiper('.p-group-intro__slide', {
    autoplay: {
      delay: 2000,
      disableOnInteraction: true,
    },
    loop: true,
    speed: 2000,
    slidesPerView: 1,
    loopAdditionalSlides: 1,
    centeredSlides: true,
    preventInteractionOnTransition: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    breakpoints: {
      740: {
        slidesPerView: 1.5,
        spaceBetween: 20,
      }
    },
  });
  var mySwiperLp = new Swiper('.p-lp-gallery__slide', {
    autoplay: {
      delay: 0,
    },
    loop: true,
    speed: 10000,
    slidesPerView: 1,
    centeredSlides: true,
    preventInteractionOnTransition: true,
    breakpoints: {
      740: {
        slidesPerView: 3.5,
        spaceBetween: 20,
      }
    },
  });



}
