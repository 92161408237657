export function nav() {
    window.onload = () => {
        let header = document.querySelector('#nav');
        let hamEl = document.querySelector('.l-hamburger__toggle');
        let hamBg= document.querySelector('.l-header__overlay');

        hamEl.addEventListener('click',() =>{
            header.classList.toggle('is-active');
        });
        hamBg.addEventListener('click',() =>{
            header.classList.toggle('is-active');
        });
    }
}
