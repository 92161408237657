export function popup() {
  function popupImage() {
    var popup = document.querySelectorAll('.js-popup');
    popup = Array.prototype.slice.call(popup, 0);
    if(!popup) return;

    var blackBg = document.querySelectorAll('.js-black-bg');
    var closeBtn = document.querySelectorAll('.js-close-btn');
    var showBtn = document.querySelectorAll('.js-show-popup');

    closePopUp(blackBg);
    closePopUp(closeBtn);
    closePopUp(showBtn);
    function closePopUp(elem) {
      if(!elem) return;
      for(let i = 0; i<elem.length; i++){
        elem[i].addEventListener('click', function() {
          popup[i].classList.toggle('is-show');
        });
      }
    }
  }
  popupImage();
}
