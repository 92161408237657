import define from './define';
export function scroll() {

  let dir = location.pathname;

  if(dir !== '/') {
    if(define.windowWidth <= define.tab_ex){
      let headEl = document.querySelector('.l-header');
      let headHeight = headEl.clientHeight;
      let mainEl = document.querySelector('.l-main');
      mainEl.style.paddingTop = headHeight + 'px';
    }
  }

  const scroll_to_top_btn = document.querySelector('#js-top-scroll');

  scroll_to_top_btn.addEventListener('click', scroll_to_top);

  function scroll_to_top(){
    window.scroll({top: 0, behavior: 'smooth'});
  };
}
