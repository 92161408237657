import Lottie from 'lottie-web';
export function loading() {


    if (document.cookie.indexOf('visited=yes') === -1) {
        document.cookie = 'visited=yes path=/';
        const animationItem = Lottie.loadAnimation({
            container: document.getElementById("is-svg"), //呼び出し位置の要素に入れたID(ここではmainvisual)を指定
            renderer: "svg", // svg・canvas・htmlの3種類から機能に応じて指定(省略可、デフォルトはsvg)
            loop: false, // ループの有無を指定(省略可、デフォルトはtrue)
            autoplay: false, // 自動再生の有無を指定(省略可、デフォルトはtrue)
            path: "/js/mama_loading.json" //ここでは相対パス「js/file.json」としているが、使用するJSONファイルがある実際のディレクトリ名・ファイル名を入れる
        });

        window.addEventListener('DOMContentLoaded',()=>{
            animationItem.play();
        });

        animationItem.addEventListener('complete',()=>{
            document.querySelector(".l-loading").classList.add('is-hide')
        });
    } else {
        // 2回目以降のアクセス
        document.querySelector(".l-loading").remove();
    }

}
