import {nav} from "./nav";
import {scroll} from "./scroll";
import {slider} from "./slider";
import { anchor } from "./anchor";
import {fontsize} from "./fontsize";
import {popup} from "./popup";
import {contact} from "./contact";
import { accordion } from "./accordion";
import {fade} from "./fade";
import {bg} from "./bg";
import {loading} from "./loading";


nav();
scroll();
slider();
anchor();
fontsize();
popup();
// accordion();
fade();
bg();
loading();

