export function fontsize() {
  let fz = document.querySelectorAll('.u-fz');
  for (let i = 0; i < fz.length; i++) {
    let max = window.getComputedStyle(fz[i]).getPropertyValue('font-size');
    let vp = window.parent.screen.width / 100;
    let vw = (max.replace(/px/g , "" ) / vp) + "vw";
    let min = (max.replace(/px/g , "" ) / 2) + "px";
    let clamp = [min,vw,max]
    fz[i].style.fontSize = 'clamp(' + clamp.join(',') + ')';
  }
}
